<template>
  <v-card :loading="loading">
    <PDF :src="pdf" @page-loaded="loading = false" />
  </v-card>
</template>

<script>
import PDF from "vue-pdf";
export default {
  components: {
    PDF,
  },
  props: {
    pdf: {
      type: String,
      default: "/test.pdf",
    },
  },
  data() {
    return {
      loading: true,
    };
  },
};
</script>

<style></style>
